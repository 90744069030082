const portfolioStr = {
  TITLE: 'Nuestros Trabajos',
  PORTFOLIO: {
    CRYPTICS: 'Cryptics',
    MI_INVERSION: 'Mi Inversion',
    OKANE: 'Okane Capital'
  },
  ERROR_OKANE: 'Lo siento. Este es un proyecto Web privado. No puedo dar mas informacion.'
}


export default portfolioStr