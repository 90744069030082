const aboutStr = {
  TITLE: 'Quienes somos',
  DESCRIPTION: 'Somos una empresa enfocada en el desarrollo de ',
  APP_WEB_MOVILE: 'Aplicaciones Web y Movil. ',
  DESCRIPTION_TWO: 'Nuestro objetivo es poder, a traves de nuestro apoyo, potenciar tu emprendimiento; tomando tu vision para poder lograr un trabajo ',
  TRABAJO_PERSONALIZADO: '100% personalizado ',
  DESCRIPTION_THREE: 'hacia ti.'
}


export default aboutStr